/* .searchbar-container{
   margin-left: 200px;
}
@media (max-width: 1534px){
    .searchbar-container{
        margin-left: 100px;
    }
} */

/* .sku-container{
    margin-left: calc(100% - 280px);
}
@media (max-width: 1023px){
    .sku-container{
        margin-left: calc(100% - 125px);
    }
}
@media (max-width: 1280px){
    .sku-container{
        margin-left: calc(100% - 125px);
    }
}
@media (max-width: 1534px){
    .sku-container{
        margin-left: calc(100% - 150px);
    }
} */
.ant-input:hover{
    border-color: unset !important;
}
.ant-input:focus{
    border-color: unset !important;
    box-shadow: unset !important;
}
.ant-btn:hover{
    color: unset !important;
    border-color: unset !important;
}
.ant-btn:focus{
    color: unset !important;
    border-color: unset !important;
}
.ant-form-item{
    margin-bottom: unset !important;
}

.ant-popover-inner{
    background-color: #2f2a41 !important;
}