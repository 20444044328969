/* header css */
#sidebar-main {
    background: #332e45;
}
.body-custom-bg{
    background-color: #312d44;
}
.new-sidebar-bg{
    background-color: #2f2a41 !important;
}
.new-dashboard-bg{
    background-color: #332e45;
}
.bg-purpal-sidebar{
    background-image: linear-gradient(#3f3962, #343056);
}
.bg-white-arrow{
    position: relative;
}
.text-custom-blue {
    color: #0ab0ca;
}

.header-container {
    position: sticky;
    top: 0;
    width: 100%;
    background: #242e42;
    z-index: 2;
}
.onMount-appear {
    opacity: 0.01;
}

.onMount-appear.onMount-appear-active {
    opacity: 1;
    transition: opacity 0.5s ease-in;
}

.rounded-50-percent {
    border-radius: 50%;
}

.z-1 {
    z-index: 1;
}

.z-2 {
    z-index: 2;
}
.w-95-percent {
    width: 95%;
}
.w-96-percent {
    width: 96%;
}

.opacity-05 {
    opacity: 0.5;
}

.h-17 {
    height: 4.5rem;
}

/* severity classes */

.bg-severity-1 {
    background-color: #660e08;
}

.bg-severity-3 {
    background-color: #ffbb33;
}

.bg-severity-4 {
    background-color: #129460;
}

.bg-severity-5 {
    background-color: #c0c1cc;
}

.text-severity-1 {
    color: #660e08;
}

.text-severity-2 {
    color: #e1392d;
}

.text-severity-3 {
    color: #ffbb33;
}

.text-severity-4 {
    color: #129460;
}

.text-severity-5 {
    color: #c0c1cc;
}

.text-severity-high {
    color: #e1392d;
}

.text-severity-medium {
    color: #ffbb33;
}

.text-severity-low {
    color: #129460;
}

.text-xxs {
    font-size: 10px;
}

/* hardcode antd design */
.ant-popover-arrow {
    /* background: #2f3b52 !important; */
    /* border-top-color: #2f3b52 !important; */
    /* border-left-color: #2f3b52 !important; */
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
    background-color: #ff4747 !important;
}

.ant-notification-notice-description {
    padding-right: 20px;
}
/* .ant-select-item-option {
    background: #20293c !important;
} */

.ant-select-item-option-active:not(.ant-select-item-option-disabled) {
    background-color: #2f3b52 !important;
}

.ant-notification-notice-message {
    margin-bottom: 0px !important;
}

.ant-message-notice-content {
    background: transparent !important;
    padding: 0px !important;
}

/* css for react file viewer */
.pg-viewer-wrapper {
    overflow-y: hidden !important;
}

.truncate-two-line {
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
}

.rcs-inner-handle {
    background: #242e42 !important;
}

.slick-next:before,
.slick-prev:before {
    content: '' !important;
}

.slick-next {
    right: -26px !important;
    width: 26px !important;
    height: 26px !important;
}

.slick-prev {
    left: -30px !important;
    width: 26px !important;
    height: 26px !important;
}

#asset-modal-container .ant-select-selector {
    border: 0px !important;
}

.ant-select-item-option {
    background-color: #242e42 !important;
}

@media (min-width: 1025px) and (max-width: 1280px) {
    /* big landscape tablets, laptops, and desktops */

    .ant-radio-button-wrapper {
        font-size: 18px !important;
    }
}

@media (min-width: 1281px) and (max-width: 1535px) {
    /* hi-res laptops and desktops */

    .ant-radio-button-wrapper {
        font-size: 14px !important;
    }
}

@media (min-width: 1536px) and (max-width: 1920px) {
    /* big landscape tablets, laptops, and desktops */

    .ant-radio-button-wrapper {
        font-size: 18px !important;
        height: 40px !important;
        line-height: 34px !important;
    }
}

.ant-table-thead > tr > th,
.ant-table-tbody > tr > td,
.ant-table tfoot > tr > th,
.ant-table tfoot > tr > td {
    padding-left: 10px !important;
    padding-right: 10px !important;
}

@media (min-width: 1024px){
    .bg-white-arrow::after{
        content: " ";
        transform: rotate( -90deg);
        position: absolute;
        top: 44%;
        right: -17%;
        border-top: 10px solid red;
        border-right: 48px transparent solid;
        border-left: 48px transparent solid;
    
    }
}

/* mobile sidebar */
@media (max-width: 1023px){
    /* big landscape tablets, laptops, and desktops */
    .mobile-sidebar{
        position: fixed;
        height: 100vh;
        left: 0;
        top: 50px;
        background-color: #242e42;
        z-index: 9;
    
    }
    .mobile-modal-root{
        height: calc(100vh - 48px) !important;
        width: 100vw !important;
        top: 55px !important;
        left: 0 !important;
        /* background-color: rgb(47, 59, 82) !important; */
        background: linear-gradient(#3f3962, #343056) !important;
        margin: unset !important;
    }
    .ant-modal-body{
        height: calc(100vh - 48px) !important;
    }
    .ant-modal{
        max-width: 100vw !important;
        top: 55px !important;
    }
    .ant-cascader {
        width: 100% !important;
    }
    .ant-select-selector{
        background-color: rgb(47, 59, 82) !important;
        border: 1px rgb(47, 59, 82) !important;
    }
    .ant-cascader-menus {
        background-color: rgb(47, 59, 82) !important;
        border: 1px rgb(47, 59, 82) !important;
    }
    .ant-select-dropdown{
        padding: unset !important;
    }
    .ant-cascader-menu-item-active{
        background-color: rgb(47, 59, 82) !important;
    }
    .ant-select-selection-item{
        color: #FFF !important
    }
    .ant-cascader-menu-item-content {
        color: #FFF !important
    }
    .ant-select-arrow {
        color: #FFF !important
    }
    .anticon-right {
        color: #FFF !important
    }
}
table {
    font-family: arial, sans-serif;
    border-collapse: collapse;
    width: 100%;
  }
  
  td, th {
    border: 1px solid #dddddd;
    text-align: left;
    padding: 8px;
  }
  
  tr:nth-child(even) {
    background-color: #dddddd;
  }