.ant-menu{
    background: unset !important;
    height: 100% !important;
}
.ant-menu-submenu {
    margin-bottom: 4px !important;
}
.ant-menu-submenu-title{
    /* padding-top: 1.25rem !important;
    padding-bottom: 1.25rem !important; */
    background-image: linear-gradient(#3f3962, #343056);
    height: 100% !important;
    width: 100% !important;
    padding-left: 15px !important;
}

.total-supply-selected{
    background-image: linear-gradient(#fff, #fff);
}
.total-supply-notselected{
    background-image: linear-gradient(#3f3962, #343056);

}

.menu-container .ant-menu-submenu-title {
    background-image: linear-gradient(#FFF, #FFF)!important;
    height: 100% !important;
    width: 100% !important;
    padding-left: 15px !important;
}
.menu-container .ant-menu-submenu-title .ant-menu-submenu-arrow::before {
    background: black!important;
}
.menu-container .ant-menu-submenu-title .ant-menu-submenu-arrow::after {
    background: black!important;
}