body {
    margin: 0;
    background: #332e45 !important;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto',
        'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans',
        'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
        monospace;
}

h1 {
    font-size: 2rem;
}
h2 {
    font-size: 1.5rem;
}
table,
tr,
thead {
    background-color: #2f2a41 !important;
}

table:hover,
tr:hover,
thead:hover {
    background: #20293c !important;
}

.ant-table-tbody > tr.ant-table-row:hover > td {
    background: none !important;
}

.ant-table-tbody > tr.ant-table-placeholder:hover > td {
    background: none !important;
}

.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(360deg);
    }
}

p {
    margin-top: 0;
    margin-bottom: 1em;
}

.ant-radio-group-solid
    .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled):focus-within {
    box-shadow: none !important;
}
